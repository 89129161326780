import type { RouteLocationNormalized, Router } from 'vue-router'
import { useAuth } from '@simplifi/auth'

export function registerGuard(router: Router) {
  router.beforeEach(async (to: RouteLocationNormalized) => {
    if (to.meta.allowAnonymous) {
      return true
    }

    const auth = useAuth()
    if (!(await auth.signIn())) {
      return { name: 'signinError' }
    }

    return true
  })
}
