import { useAuth } from '@simplifi/auth'
import Axios from 'axios'

const api = Axios.create()

export function configureApiService() {
  api.interceptors.request.use(async (config) => {
    config.baseURL = import.meta.env.VITE_APP_API_BASE_URI
    return config
  })

  api.interceptors.request.use(async (config) => {
    if (config.headers && !config.headers.Authorization) {
      config.headers.Authorization = `bearer ${await useAuth().getToken()}`
    }
    return config
  })

  api.interceptors.response.use(
    (response) => {
      return response
    },
    (error) => {
      if (error.response) {
        console.error(
          ['API response failed', error.message, error.response?.data?.Message]
            .filter(Boolean)
            .join(' | '),
          error,
        )
      } else if (error.request) {
        console.error(
          ['API request failed', error.message, `URL: ${error.config?.url}`]
            .filter(Boolean)
            .join(' | '),
          error,
        )
      } else {
        console.error('API error', error)
      }
      return Promise.resolve(error.response || error.request || { status: 400 })
    },
  )

  return api
}

export default api
