<template>
  <v-app-bar class="justify-start pl-1 pr-1" :floating="false" color="primary">
    <v-app-bar-nav-icon @click.stop="menuClicked" />
    <img src="@/assets/logo.svg" class="logo" />
    <v-app-bar-title class="mr-5 toolbar__title">
      SIMPLIFi Development
    </v-app-bar-title>
    <v-app-bar-title> </v-app-bar-title>
    <v-spacer />
    <SfiAppSwitcher class="mr-1" :apps="session.applications" />
    <SfiUserMenu class="mr-1" @signOut="auth.signOut()" :user="session" />
  </v-app-bar>
</template>

<script lang="ts" setup>
import { useAuth } from '@simplifi/auth'

const props = withDefaults(defineProps<{ modelValue: boolean | null }>(), {
  modelValue: null,
})

const emit = defineEmits(['update:modelValue'])

const auth = useAuth()
const session = auth.session

function menuClicked() {
  emit('update:modelValue', !props.modelValue)
}
</script>

<style lang="scss">
.logo {
  max-width: 30px;
  margin-left: 1rem;
}

.toolbar__title {
  flex: 0 1 auto !important;
}
</style>
