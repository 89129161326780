// Styles
import 'vuetify/styles'
import '@simplifi/components/styles'

import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

// Framework
import { createApp } from 'vue'
import { createPinia } from 'pinia'

import App from './App.vue'
import router from './router'
import { configureApiService } from '@/api/api'

import { useAuth } from '@simplifi/auth'
import { initLogging } from '@simplifi/logging'
import { createSimplifiComponents } from '@simplifi/components'

// Import our CSS last so they get loaded after Vuetify's
import './style.scss'
import { vuetifyOptions } from './plugins/vuetify'

initLogging({
  datadogApplicationId: import.meta.env.VITE_APP_DD_APPLICATION_ID,
  datadogClientToken: import.meta.env.VITE_APP_DD_CLIENT_TOKEN,
  environmentName: import.meta.env.VITE_APP_DD_ENVIRONMENT,
  serviceName: import.meta.env.VITE_APP_DD_SERVICE,
  version: import.meta.env.VITE_APP_DD_CI_BUILD_ID,
  commitSha: import.meta.env.VITE_APP_DD_GIT_COMMIT_SHA,
  repositoryUrl: import.meta.env.VITE_APP_DD_GIT_REPO_URL,
  allowedUrls: [import.meta.env.VITE_APP_API_BASE_URI],
})

// Required for dayjs to display time from x used by the PR age column.
// https://day.js.org/docs/en/display/from
dayjs.extend(relativeTime)

const app = createApp(App)
app.use(createPinia())

configureApiService()
await useAuth().init({
  apiBaseUri: import.meta.env.VITE_APP_API_BASE_URI,
  router: router,
})

app.use(router)

app.use(
  createSimplifiComponents({
    vuetify: vuetifyOptions,
  }),
)

app.mount('#app')
