<template>
  <v-app :theme="themeName" class="application">
    <router-view v-slot="{ Component }">
      <component :is="Component" v-if="Component" />

      <Loading v-else />
    </router-view>
  </v-app>
</template>

<script setup lang="ts">
import Loading from '@/components/general/app-loading.vue'
import { useTheme } from '@simplifi/components'

const { themeName } = useTheme()
</script>
