<script setup lang="ts">
import { useRouter } from 'vue-router'
import { REDIRECT_PATH_STORAGE_KEY, useAuth } from '@simplifi/auth'

const path = localStorage.getItem(REDIRECT_PATH_STORAGE_KEY)
const router = useRouter()

if ((await useAuth().getToken()) && path) {
  router.replace({ path })
} else {
  router.push({ name: 'signinError' })
}
</script>