import { createRouter, createWebHistory, type RouteRecordRaw } from 'vue-router'
import DefaultLayout from '@/layouts/default-layout.vue'
import AuthLayout from '@/layouts/auth-layout.vue'
import IdentityCallback from '@/views/authentication/identity-callback.vue'
import { registerGuard } from './router-guard'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/home',
    component: DefaultLayout,
    children: [
      {
        path: 'home',
        name: 'home',
        component: () => import('@/views/home-view.vue'),
      },
      {
        path: 'pull-requests',
        name: 'pull-requests',
        component: () => import('@/views/pull-requests-view.vue'),
      },
      {
        path: 'configuration',
        name: 'configuration',
        component: () => import('@/views/configuration-view.vue'),
      },
      {
        path: 'secrets',
        name: 'secrets',
        component: () => import('@/views/secrets-view.vue'),
      },
      {
        path: 'features',
        name: 'features',
        redirect: '/features/active',
        children: [
          {
            path: 'rollout',
            name: 'features-rollout',
            component: () => import('@/views/features-rollout-view.vue'),
          },
          {
            path: 'active',
            name: 'features-active',
            component: () => import('@/views/features-active-view.vue'),
          },
          {
            path: 'permanent',
            name: 'features-permanent',
            component: () => import('@/views/features-permanent-view.vue'),
          },
          {
            path: 'deprecated',
            name: 'features-deprecated',
            component: () => import('@/views/features-deprecated-view.vue'),
          },
        ],
      },
      {
        path: 'deployments',
        name: 'deployments',
        redirect: '/deployments/production',
        children: [
          {
            path: ':environmentId',
            name: 'deployments-environment',
            component: () => import('@/views/deployments-recent-view.vue'),
          },
        ],
      },
      {
        path: 'deployment-overview',
        name: 'deployment-overview',
        component: () => import('@/views/deployment-overview-view.vue'),
      },
    ],
  },
  {
    path: '/identity/callback',
    component: AuthLayout,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '',
        name: 'identityCallback',
        component: IdentityCallback,
      },
    ],
  },
  {
    path: '/signin/error',
    name: 'signinError',
    component: () => import('@/views/authentication/signin-error.vue'),
    meta: {
      title: 'Signin Error',
    },
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

registerGuard(router)

export default router
